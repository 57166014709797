footer {
	background: #32373d;
	color: #fff;
	padding: 25px;
	text-align: center;

	@media (min-width: $start-lg) {
		padding: 45px;
	}

	p {
		font-size: 14px;
		line-height: 1;
		margin: 0;
	}

	a {
		font-size: 14px;
		line-height: 1;
		margin: 0;
		color: white;
	}

	img {
		display: block;
		margin: 0 auto;
		width: 100%;
	}

	#copyright-logo{
		display: inline;
		height: 10px;
		width: auto;
	}

	@media (max-width: 470px){
		a {
			display: block;
			padding-top: 10px;
		}
		.hide-small {
			display: none;
		}
		#copyright-logo {
			margin-top: 15px;
			display: block;
		}
	}
}
