%wai {
	height:0;
	margin: 0;
	overflow: hidden;
	padding: 0;
	position: absolute;
	text-indent: -9999em;
	-webkit-appearance: none;
	width: 0;
	z-index: -1;
}