$start-sm: 400px;
$start-md: 700px;
$start-lg: 900px;

$blue: #19b6fe;
$teal: #30c9b4;
$text: #32373d;
$green: #2ecc71;

$header-height-sm: 72px;
$header-height-md: 100px;

@import 'normalize', 
		'mixins',
		'landing/layout',
		'landing/header',
		'landing/overview',
		'landing/product-tour',
		'landing/why-swagify',
		'landing/footer',
		'landing/form',
		'landing/email-subscription-modal';