#why-swagify {
	background: $blue;
	padding: 30px 25px;
	position: relative;

	@media (min-width: $start-lg) {
		padding: 45px 50px;
	}

	h1 {
		@media (min-width: $start-lg) {
			margin-bottom: 0.85em;
		}
	}

	table {
		margin: 0 -25px;
		display: block;

		thead {
			@extend %wai;
		}

		tbody, tr, td {
			display: block;
		}

		tr {
			border-top: 1px solid #26769e;
			padding: 15px 25px;

			&:last-child {
				border-bottom: 1px solid #26769e;
			}
		}

		td {
			align-items: center;
			border-bottom: 1px solid #2092c8;
			display: flex;
			flex-flow: row nowrap;
			font-size: 13px;
			padding: 5px 0;

			&:first-child, &:last-child {
				border: 0;
			}

			span {
				&:nth-child(1) {
					margin-right: 15px;
					width: 60%;

					@media (min-width: $start-md) {
						width: 45%;
					}
				}
			}

			.wai {
				&:after {
					content: ":";
				}
			}
		}

		button {
			border: 0;
			background: none;
			color: $text;
			font: 1.65em 'bebas-neue', sans-serif;
			margin: -15px -25px 0;
			padding: 15px 25px;
			text-align: left;
			-webkit-appearance: none;
			width: calc(100% + 25px);
			position: relative;
		}
	}	

	.yes, .no {
		background-repeat: no-repeat;
		background-size: auto 100%;
		background-image: url("/img/landing/table-yesno-icons.png");
		display: block;
		height: 20px;
		width: 20px;
		text-align: left;
		text-indent: -9999em;
		position: relative;

		b {
			position: absolute;
			left: 50%;
			transform: translate3d(-50%,0,0);
			text-indent: 0;
			bottom: -1.5em;
			font-size: 9px;
			width: 300%;
			text-align: center;
		}
	}

	.yes {
		background-position: right top;
	}

	.no {
		background-position: left top;
	}

	.na {
		font-size: 2em;
		line-height: 0;
		text-indent: 4px;
	}

	@media (min-width: $start-lg) {
		table {
			border: 2px solid $text;
			display: table;
			margin: 0;
			width: 100%;

			thead {
				height: auto;
				overflow: auto;
				width: auto;
				text-indent: 0;
				position: static;
			}

			tbody {
				display: table-row-group;
			}

			tr {
				display: table-row;
			}

			td, th {
				display: table-cell;
				text-align: center;
				width: 14%;
				border-left: 2px solid $text;
				border-right: 2px solid $text;
				padding: 20px 10px;

				&:nth-child(1) {
					width: 16%;
				}
			}

			td {
				border-bottom: 1px solid $text;
			}

			tbody tr:nth-child(1) {
				background: #1ca8eb;
			}

			th {
				font-size: 0.875em;
				font-weight: normal;
				line-height: 1.125;
				border-bottom: 2px solid $text;
			}

			button {
				margin: 0;
				padding: 0;
				text-align: center;
				width: auto;
			}

			.wai {
				height:0;
				margin: 0;
				overflow: hidden;
				padding: 0;
				position: absolute;
				text-indent: -9999em;
				-webkit-appearance: none;
				width: 0;
				z-index: -1;
			}

			.yes, .no {
				margin: 0 auto;
			}
		}
	}

	.why-swagify-table, .why-swagify-cta {
		position: relative;
	}

	.why-swagify-cta {
		margin: 3em 0 2em;
		text-align: center;

		@media (min-width: $start-md) {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
		}

		@media (min-width: $start-lg) {
			flex-flow: row nowrap;
			margin: 4em -40px 2em;
		}

		> div {
			margin: 0 auto 4em;
			display: flex;
			flex-flow: column;
			align-items: center;
			justify-content: space-between;


			&:last-child {
				margin-bottom: 0;
			}

			@media (min-width: $start-md) {
				&:not(:last-child) {
					width: calc(50% - 1px);
				}

				&:nth-child(2) {
					border-left: 1px solid #26769e;
				}
			}

			@media (min-width: $start-lg) {
				box-sizing: border-box;
				margin: 0;
				padding: 0 40px;
				width: 33.333%;

				&:not(:last-child) {
					width: 33.333%;
				}

				&:nth-child(3) {
					border-left: 1px solid #26769e;
				}
			}
		}

		h2, button, a {
			max-width: 22em;
		}

		p {
			max-width: 23em;
		}

		h2 {
			font-size: 2em;
			margin-bottom: 10px;
			line-height: 1;
		}
		p {
			font-size: 13px;
			margin: 10px auto 20px;
		}
		p.price-text {
			font-size: 18px;
			font-weight: bold;
			}

		a, button {
			width: 100%;

			@media (min-width: $start-md) and (max-width: $start-lg - 1) {
				max-width: 18em;
				padding: 15px;
			}

			@media (min-width: $start-lg) {
				padding: 10px 20px;
			}
		}

		b {
			font-family: 'bebas-neue';
			font-weight: normal;
			font-size: 1.5em;
		}
	}
}

.js #why-swagify {
	@media (max-width: $start-lg - 1) {
		table tr {
			height: 4em;
	    	box-sizing: border-box;
	    	overflow: hidden;
	    	transition: height 200ms ease;
	    	transform: translate3d(0,0,0);

	    	&[data-active="true"] {
	    		button:before {
	    			height: 0;
	    		}
	    	}
		}

		tr button {
			&:before, &:after {
				background: $text;
				content: "";
				width: 2px;
				height: 20px;
				position: absolute;
				right: 10px;
				top: 50%;
				transform: translate3d(0, -50%, 0);
				transition: all 200ms ease;
			}

			&:before {
				transform: translate3d(0, -50%, 0);
			}

			&:after {
				transform: translate3d(0, -50%, 0) rotate(90deg);
			}
		}
	}

	table tr[data-active="true"] {
		background: #1ca8eb;
		height: 21em;

		@media (min-width: $start-sm) {
			height: 18em;
		}

		@media (min-width: $start-lg) {
			height: auto;
		}
	}
}