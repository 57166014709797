header {
	box-sizing: border-box;
	height: $header-height-sm;
	padding: 20px 35px;
	transition: all 200ms ease;
	transform: translate3d(0,0,0);
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 5;

	@media (min-width: $start-md) {
		height: $header-height-md;
		padding: 30px 45px;
	}

	.inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	#logo {
		display: block;

		img {
			display: block;
			height: 32px;
			transition: all 200ms ease;
			transform: translate3d(0,0,0);
			width: auto;

			@media (min-width: $start-md) {
				height: 40px;
			}
		}
	}

	nav {
		opacity: 0;
		transition: all 200ms ease;
		transform: translate3d(0,0,0);
		
		@media (min-width: $start-md) {
			opacity: 1;
		}

		ul {
			display: flex;
			flex-flow: row nowrap;
			margin: 0;
			padding: 0;

			li {
				display: block;

				a {
					display: block;
					font-size: 12px;
					padding: 0 20px;
					text-transform: uppercase;
					letter-spacing: 0.05em;
					transition: all 200ms ease;

					@media (min-width: $start-lg) {
						font-size: 13px;
					}

					&:hover {
						span:before {
							left: 0;
							right: 0;
							opacity: 0.5;
						}
					}
				}

				&:last-child a {
					padding-right: 0;
				}

				span {
					display: block;
					position: relative;

					&:before {
						background: $text;
						content: "";
						height: 2px;
						opacity: 0;
						transition: all 200ms ease;
						position: absolute;
						bottom: -8px;
						left: 50%;
						right: 50%;
					}
				}

				a[data-active="true"] span {
					&:before {
						left: 0;
						right: 0;
						opacity: 1;
					}
				}
			}
		}
	}
}

[data-scrolling="true"] {
	header {
		background: rgba($blue, 0.85);
		height: $header-height-sm - 18px;
		padding: 15px 35px;

		#logo img {
			height: 25px;
		}

		nav ul li a {
			font-size: 10px;

			span:before {
				bottom: -5px;
			}
		}
	}
}