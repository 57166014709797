@import url("https://fonts.googleapis.com/css?family=Lato:700i,900i, 400, 900|Roboto:400, 900|Montserrat:400,700");

html, body {
	color: $text;
	font: normal 16px 'Montserrat', sans-serif;
	height: 100%;
	line-height: 1.4;
	margin: 0;
	padding: 0;
	width: 100%;
	-webkit-font-smoothing: antialiased;
}

#skipnav {
	@extend %wai;
}

main {
	overflow: hidden;
}

section {
	padding: 20px 35px;

	@media (min-width: $start-md) {
		padding: 30px 45px;
	}
}


.inner {
	max-width: 1300px;
	margin: 0 auto;
	position: relative;
	z-index: 2;
}

h1, h2, h3 {
	margin-top: 0;
}

h1 {
	font: bold 1.75em/1.25 'Lato', sans-serif;
	font-style: italic;
	margin-bottom: 16px;

	@media (min-width: $start-md) {
		font-size: 2.25em;
	}
}

h2 {
	font-family: 'bebas-neue', sans-serif;
	font-weight: normal;
}

a {
	color: $text;
	text-decoration: none;
}

.button-link, .button-link-knockout {
	border: 2px solid $text;
	background: none;
	box-sizing: border-box;
	border-radius: 30px;
	display: inline-block;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 0.05em;
	padding: 10px 40px;
	text-transform: uppercase;
	transform: translate3d(0,0,0);
	transition: all 200ms ease;
	text-align: center;

	&:hover, &:focus {
		transform: translate3d(-2px,-2px,0);
		box-shadow: 2px 2px 0 1px rgba(0,0,0,0.125);
	}

	@media (min-width: $start-lg) {
		font-size: 14px;
	}
}

.button-link {
	background: $text;
	color: #fff;

	&:hover, &:focus {
		background: $green;
		border-color: $green;
	}
}

.button-link-knockout {
	&:hover, &:focus {
		background: $text;
		color: #fff;
	}
}
