#overview {
	background: linear-gradient(to bottom, $blue 0%, $teal 100%);
	padding-top: $header-height-sm + 20px;
	position: relative;

	@media (min-width: $start-md) {
		padding-top: $header-height-md + 20px;
	}

	&:after {
		background: url("/img/landing/overview-texture.png") no-repeat right top / 100% auto;
		bottom: 0;
		content: "";
		position: absolute;
		z-index: 1;
		opacity: 0.15;
		height: 60%;
		width: 100%;

		@media (min-width: $start-md) {
			background-position: left top;
			background-size: auto;
			left: 0;
			width: 70%;
		}
	}

	.inner {
		position: relative;
		z-index: 2;
	}

	p:not(.overview-cta) {
		font: bold 1.125em/1.5 'Lato', sans-serif;
		font-style: italic;
		margin: 16px 0;
		max-width: 25em;

		@media (min-width: $start-md) {
			font-size: 1.45em;
		}
	}

	.highlight {
		color: #90ff00;
	}

	.overview-cta {
		margin: 2em 0;

		a {
			display: block;
			max-width: 23em;
			margin-bottom: 1.5em;
			width: 100%;

			@media (min-width: $start-md) {
				display: inline-block;
				margin-right: 25px;
				padding: 10px 25px;
				width: auto;
			}
		}
	}

	#overview-highlights {
		font-size: 12px;
		list-style: none;
		margin: 1em 0 0;
		padding: 0;
		text-transform: uppercase;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;

		@media (min-width: $start-sm) {
			font-size: 13px;
		}

		@media (min-width: $start-md) {
			font-size: 15px;
		}

		@media (min-width: $start-lg) {
			font-size: 16px;
			margin: 3em 0;
			width: 33em;
		}

		li {
			box-sizing: border-box;
			display: flex;
			flex-flow: row nowrap;
			margin-bottom: 1.5em;
			position: relative;
			padding: 0;
			max-width: 14em;
			width: 45%;

			@media (min-width: $start-md) and (max-width: $start-lg - 1) {
				width: 33.33%;
			}

			&:before {
				background-image: url("/img/landing/overview-icons.png");
				background-size: auto 100%;
				background-repeat: no-repeat;
				content: "";
				height: 25px;
				margin: 0.35em 15px 0 0;
				display: block;
				top: 0.35em;
				left: 0;
				min-width: 25px;
				width: 25px;
				-webkit-backface-visibility: hidden;
				transform: translate3d(0,0,0);

				@media (min-width: 400px) {
					height: 33px;
					width: 33px;
					min-width: 33px;
				}
			}

			&:nth-child(1):before {
				background-position: -1% -3px;
			}

			&:nth-child(2):before {
				background-position: 24% 0;
			}

			&:nth-child(3):before {
				background-position: 43% 1px;
			}

			&:nth-child(4):before {
				background-position: 63% 0;
			}

			&:nth-child(5):before {
				background-position: 82% 0;
			}

			&:nth-child(6):before {
				background-position: 101.5% 0;
			}

			span {
				display: block;
			}
		}
	}

	#overview-image {
		background: url("/img/landing/laptop-sm.png") no-repeat center / 100% auto;
		margin: 1em -30px -60px;

		&:before {
			content: "";
			display: block;
			padding-bottom: 57.37%;
			width: 100%;
		}

		@media (min-width: $start-md) {
			background-image: url("/img/landing/laptop-md.png");
			margin: 3em -45px -80px;
		}

		@media (min-width: $start-lg) {
			background-image: url("/img/landing/laptop-lg.png");
			position: absolute;
		    width: 70%;
		    bottom: -8em;
		    right: -28%;
		    margin: 0;
		    transition: all 200ms ease;
		}

		@media (min-width: 950px) {
			right: -25%;
		}

		@media (min-width: 1000px) {
			right: -20%;
		}

		@media (min-width: 1100px) {
			right: -15%;
		}

		@media (min-width: 1250px) {
			bottom: -10em;
			right: -12%;
		}
	}
}

.js #overview {
	#overview-image {
		@media (min-width: $start-lg) {
			bottom: -6em;
		}
	}
}