#product-tour {
	background: #f6f6f6;
	padding-top: 80px;
	position: relative;
	overflow: hidden;
	text-align: center;

	&:before, &:after {
		content: "";
		opacity: 0.15;
		position: absolute;
		transform: translate3d(0,0,0);
		transition: all 200ms ease;
		z-index: 1;
	}

	&:before {
		background: url("/img/landing/product-tour-texture-top.png") no-repeat left top / 100% auto;
		height: 50%;
		left: 0;
		width: 50%;
		top: 0;

		@media (min-width: $start-md) {
			width: 33.3%;
		}

		@media (min-width: $start-lg) {
			width: 370px;
		}
	}

	&:after {
		background: url("/img/landing/product-tour-texture-bottom.png") no-repeat bottom right / 100% auto;
		bottom: 3em;
		height: 50%;
		right: 0;
		width: 60%;

		@media (min-width: $start-md) {
			width: 40%;
		}

		@media (min-width: $start-lg) {
			width: 370px;
		}
	}

	h1 {
		@media (min-width: $start-md) {
			margin-top: 1.25em;
		}
	}

	p.intro {
		font: bold 1em/1.5 'Lato', sans-serif;
		font-style: italic;

		@media (min-width: $start-md) {
			margin-bottom: 4em;
		}
	}

	.product-tour-step {
		bottom: 0;
		margin: 3em auto;
		max-width: 900px;
		position: relative;
		text-align: left;

		@media (min-width: $start-md) {
			margin: -10px auto;
		}

		h2 {
			color: $green;
			font-size: 1.75em;
			margin: 20px 0;

			@media (min-width: $start-lg) {
				font-size: 2em;
			}
		}

		p {
			font-size: 0.875em;
			line-height: 1.5;
			margin: 0;
		}

		@media (min-width: $start-md) {
			display: flex;
			align-items: center;
			justify-content: center;

			&:nth-child(even) {
				div:not(.product-tour-image) {
					margin-left: 55px;
				}
			}

			&:nth-child(odd) {
				flex-flow: row-reverse;
				div:not(.product-tour-image) {
					margin-right: 55px;
				}
			}
		}

		@media (min-width: $start-lg) {
			margin: 0 auto;
			justify-content: flex-start;
		}

		div:not(.product-tour-image) {
			@media (min-width: $start-md) {
				flex: 0 1 auto;
				max-width: 17em;
			}

			@media (min-width: $start-lg) {
				max-width: 20em;
			}
		}
	}

	.product-tour-branding-label {
		text-transform: uppercase;
		@media (min-width: $start-md) {
			margin-bottom: 1.5em;
			margin-top: 3em;
		}

		@media (min-width: $start-md) {
			margin-top: 6em;
		}
	}

	.product-tour-branding {
		display: flex;
		justify-content: center;
		align-items: center;
		max-width: 500px;
		margin: 0 auto 3em;
		transform: translate3d(-3%,0,0);

		@media (min-width: $start-md) {
			margin-bottom: 6em;
		}

		a, span {
			display: inline-block;
			padding: 12px 0 8px;

			@media (min-width: $start-md) {
				padding: 20px 0 10px;
			}
		}

		span {
			flex: 0 0 auto;
		}

		img {
			height: auto;
			width:100%;
		}

		span {
			background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAAqElEQVQ4ja2U0Q2AIAxED+MCOoIrmDCBKziLjqCzOAsrsIIr+EMTUnqIiZfwwUWe15bgvPeoaAUwGf6Zb/oaIUFW5QUN6V4gi+EFbdQgQ1pa8QtkJn4BkZ5MKGtnkBmq2QJZAByVVLk2tb+kHGuMrQoCYdFbFP+AhA58lC26JckNwKm1k0Oj+m4E+D2xyovph4UYxJpWcd3fICxJM4RNiiZhT4HVWAp5AGceFqHrv+4xAAAAAElFTkSuQmCC) no-repeat center;
			margin: 0 15px;
			width: 55px;
			text-indent: -9999em;
			background-size: 15px;
			border-top: 1px solid #969696;
			border-bottom: 1px solid #969696;
			align-self: stretch;

			@media (min-width: $start-md) {
				background-size: 20px;
				width: 75px;
			}
		}
	}

	.product-tour-image {
		border: 1px solid #e3e3e3;
		border-radius: 100%;
		flex: 1 0 auto;
		overflow: hidden;
		max-width: 350px;
		margin: 0 auto 2em;
		position: relative;
		width: 90%;
		z-index: 2;

		@media (min-width: $start-md) {
			margin: 0;
			max-width: 350px;
			width: 50%;
		}

		@media (min-width: $start-lg) {
			max-width: 400px;
		}

		&:before {
			content: "";
			display: block;
			padding-bottom: 100%;
			width: 100%;
		}

		&.left {
			box-shadow: -15px 10px 0 5px #ededed
		}

		&.right {
			box-shadow: 15px 10px 0 5px #ededed
		}

		img {
			position: absolute;
			left: 50%;
			transform: translate3d(-50%,0,0);
			height: auto;
			width: 100%;
			top: 0;
			z-index: 1;
		}
	}

	.product-tour-trial {
		background: $green;
		border-radius: 20px 20px 0 0;
		color: #fff;
		margin: 2.5em -10px -20px;
		padding: 25px 15px 15px;

		@media (min-width: $start-sm) {
			align-items: center;
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-around;
		}

		@media (min-width: $start-md) {
			padding: 35px 45px 30px;
		}

		@media (min-width: $start-lg) {
			padding: 45px 100px 30px;
			width: calc(100% - 100px);
    		box-sizing: border-box;
		}

		> div {
			@media (min-width: $start-sm) {
				text-align: left;
				width: 45%;
			}

			@media (min-width: $start-md) {
				flex: 1 0 auto;
				margin-right: 35px;
				width: 60%;
			}
		}

		.button-link {
			padding: 10px;
			width: 100%;

			@media (min-width: $start-sm) {
				width: 45%;
			}

			@media (min-width: $start-md) {
				max-width: 17em;
				width: 35%;
			}

		}

		h2 {
			font-size: 1.75em;
			line-height: 1;
			margin-bottom: 16px;

			@media (min-width: $start-md) {
				font-size: 2.5em;
			}
		}

		p {
			font-size: 14px;

			@media (min-width: $start-md) {
				font-size: 16px;
			}
		}

		@media (min-width: $start-md) {
			margin: 7em auto -30px;
		}
	}
}