@keyframes slideDown {
	0% {
		transform: translate3d(0,-100%,0);
	}
	100% {
		transform: translate3d(0,0,0);
	}
}

body[data-form-open="true"] {
	&:before {
		content: "";
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		background: rgba(255,255,255,0.75);
		width: 100%;
		animation: slideDown 500ms ease;
		animation-fill-mode: forwards;
		z-index: 100;
		-webkit-backface-visibility: hidden;
	}

	#contact-form {
		display: block;
		animation: slideDown 500ms ease 400ms;
		animation-fill-mode: forwards;

		@media (min-height: 500px) {
			display: flex;
		}
	}
}

#contact-form {
	align-items: center;
	box-sizing: border-box;
	display: none;
	height: 100%;
	justify-content: center;
	padding: 15px;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 102;
	transform: translate3d(0,-100%,0);
	-webkit-backface-visibility: hidden;

	&-response {
		display: none;

		> div {
			display: flex;
			flex-flow: column;
			align-items: flex-start;
			justify-content: space-between;
			min-height: 340px;
		}

		p {
			font-size: 1.5em;
			text-align: center;
		}
	}

	form {
		background: #fff;
		border: 1px solid #e1e1e1;
		border-radius: 20px;
		box-sizing: border-box;
		margin: 0 auto;
		min-height: 400px;
		max-width: 650px;
		padding: 20px;
		width: 100%;

		@media (min-width: $start-lg) {
			border-radius: 30px;
			padding: 30px;
		}

		&[data-loading] {
			> div {
				display: none;
			}
			background: #fff url("/img/landing/ripple.svg") no-repeat center;
		}
	}

	input, textarea {
		border: 1px solid #e1e1e1;
		border-radius: 8px;
		box-sizing: border-box;
		display: block;
		margin-bottom: 20px;
		padding: 10px 15px;
		width: 100%;

		&:focus {
			border-color: $blue;
		}
	}

	textarea {
		height: 150px;
		resize: none;
	}

	label {
		@extend %wai;
	}

	button {
		background: $green;
		border-color: $green;
	}
}