$modal-width: 500px;
$modal-height: 430px;
$modal-content-padding: 30px;

.modal-overlay {
    display: none;
}
.modal-overlay.open {
    background-color: rgba(0, 0, 0, 0.7);
    height: 100%;
    width: 100%;
    position: fixed;
    display: block;
    z-index: 10;
    top: 0px;

}
.subscription-modal {
    z-index: 2;

    @media (max-height: 450px) {
        height: calc(100% - 10px);

        .gradient {
            height: calc(100% - 10px);
        }

        .subscription-modal-content {
            overflow: auto;
            height: calc(100% - 6px);
            padding: $modal-content-padding / 1.5;
        }
    }

    @media (max-width: 600px) {
        width: calc(100% - 10px);
        
        .gradient {
            width: calc(100% - 10px);
        }

        .subscription-modal-content {
            width: calc(100% - 6px);

            .subscription-modal-input input[type=email] {
                width: calc(100% - 50px);
            }
        }
    }

    @media (min-height: 451px) {
        height: $modal-height;

        .gradient {
            height: ($modal-height - 10px);
        }

        .subscription-modal-content {
            height: ($modal-height - 16px);
            padding: $modal-content-padding;
        }
    }
    
    @media (min-width: 601px) {
        width: $modal-width;

        .gradient {
            width: ($modal-width - 10px);
        }

        .subscription-modal-content {
            width: ($modal-width - 16px);

            .subscription-modal-input input[type=email] {
                width: ($modal-width - 130px);
            }
        }
    }

    position: fixed;
    top: 30%;
    top: calc(50% - 100px);
    right: -($modal-width + 50px);
    padding: 0;
    background: #ffffff;    
    border: 2px solid #e1e1e1;
    /* border-right: 0;
    border-radius: 21px 0 0 21px; */
    transition: all 1.2s;

    .gradient {
        background: linear-gradient(#22bce2, #3bd292);
        position: absolute;
        left: 5px;
        top: 5px;
    }

    .subscription-modal-content {
        box-sizing: border-box;
        position: absolute;
        background: #FFF;
        left: 3px;
        top: 3px;

        .subscription-modal-message, .subscription-modal-success {
            .modal-title{
                text-transform: uppercase;
                font-size: 17px;
                font-style: normal;
                letter-spacing: 0.04em;
                word-spacing: 0.4em;
            }
            font-family: 'Lato';
            font-style: italic;
            font-size: 23px;
            text-align: center;

            span {
                display: inline-block;
                padding: 15px 0px 10px 0px;
            }
        }

        .spacer {
            width: 120px;
            border-bottom: 2px solid $green;
            display: inline-block;
        }
        .subscription-modal-message, .subscription-modal-input {
            padding: 0;
            padding-bottom: $modal-content-padding;
            text-align: center;
        }

        .subscription-modal-input {
            text-align: center;
            input {
                font-size: 14.7px;
                font-weight: 600;
                padding: 10px 25px;
                border-radius: 10px;
                border: 1px solid #d7d7d7;
            }

            p {
                font-size: 12px;
                margin: 0;
                padding-top: 10px;
            }
        }

        .subscription-modal-button {
            padding: 0;
            text-align: center;

            input {
                padding: 10px 50px;
                font-size: 14px;
            }

            .button-link-email {
                background: $green;
		        border-color: $green;

                &:hover, &:focus {
	                color: #fff;
                    background: $text;
		            border-color: $text;
                }
            }
        }

        .subscription-modal-success {
            width: 100%;
            padding: 0;
            text-align: center;
            display: none;

            img {
                padding-bottom: 10px;
            }

            .subscription-modal-success-message {
                margin: 0 auto;
                font-family: 'Lato';
                font-style: italic;
                font-size: 23px;
                margin-bottom: 2em;
            }
            
        }
        
    }

    .close-button {
        position: absolute;
        top: 15px;
        right: 15px;
        padding: 15px;
        cursor: pointer;

    }

    .subscription-modal-close {
        padding: 37px 27px 0px 20px;
        float: right;

        #subscription-modal-close-btn {
            cursor: pointer;
        }
    }
}

.subscription-modal-open {
    right: 0px;
    left: 0px;
    margin: auto;
    top: 0px;
    bottom: 0px;
}